import React, { Component } from 'react';
import { Routes, Route } from 'react-router-dom';
import Web3 from 'web3';
import NavBar from './NavBar.jsx';
import Home from './Home.jsx';
import Data from './Data.jsx';
import NFT from './NFT.jsx';
import Track from './Track.jsx';
import About from './About.jsx';
import UDRsSC from '../abis/UniversityDiplomaRecords.json';
import NFTsSC from '../abis/UDR_NFT.json';
import './App.css';

class App extends Component {

    async componentDidMount() {
        await this.loadWeb3();
        await this.loadBlockchainData();
    }

    async loadWeb3() {
        if(window.ethereum){
            window.web3 = new Web3(window.ethereum);
            await window.ethereum.enable();
        }else if(window.web3){
            window.web3 = new Web3(window.web3.currentProvider);
        }else{
            window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!');
        }
    }

    async loadBlockchainData() {
        const web3 = window.web3;
        // Load account
        const accounts = await web3.eth.getAccounts();
        this.setState({ account: accounts[0] });

        // Network ID
        const networkId = await web3.eth.net.getId();
        const networkData_UDR = UDRsSC.networks[networkId];
        const networkData_NFT = NFTsSC.networks[networkId];
        
        if(networkData_UDR && networkData_NFT){
            const UDRs_SC = web3.eth.Contract(UDRsSC.abi, networkData_UDR.address);
            const recordsCount = await UDRs_SC.methods.recordsCount().call();
            const NFTs_SC = web3.eth.Contract(NFTsSC.abi, networkData_NFT.address);

            // load Diploma Records
            for(let i = 1; i <= recordsCount; ++i){
                try {
                    const diplomaRecord = await UDRs_SC.methods.diplomaRecords(i).call();
                    const whitelistedId = await NFTs_SC.methods.whitelistedId(i).call();
                    const mintedNFTId = await NFTs_SC.methods.nftId(i).call();
    
                    this.setState({
                        diplomaRecords: [...this.state.diplomaRecords, diplomaRecord],
                        whitelistedIds: [...this.state.whitelistedIds, whitelistedId],
                        mintedNftIds: [...this.state.mintedNftIds, mintedNFTId],
                    });
                } catch(error) {
                    console.log(error)
                }
            }

            this.setState({ 
                UDRs_SCaddress: networkData_UDR.address,
                UDRs_SC,
                recordsCount,
                NFTs_SCaddress: networkData_NFT.address,
                NFTs_SC,
                loading: false 
            });
        }else{
            window.alert('University Diploma Records contract not deployed to detected network!');
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            account: '',
            UDRs_SCaddress: null,
            UDRs_SC: null,
            diplomaRecords: [],
            NFTs_SCaddress: null,
            NFTs_SC: null,
            whitelistedIds: [],
            mintedNftIds: [],
            loading: true
        };
    }

    render() {
        return (
            <>
                <NavBar account={this.state.account} />
                <Routes>
                    <Route path='/about' element={<About />} />
                </Routes>
                {
                    this.state.loading
                    ? 
                    <div id="loader" className="text-center mt-5"><p>Loading...</p></div>
                    :
                    <Routes>
                        <Route path='/' element={
                            <Home 
                                activeAccount={this.state.account}
                                UDRs_SC={this.state.UDRs_SC}
                                diplomaRecords={this.state.diplomaRecords}
                            />}
                        />
                        <Route path='/data' element={
                            <Data 
                                activeAccount={this.state.account}
                                NFTs_SCaddress={this.state.NFTs_SCaddress}
                                NFTs_SC={this.state.NFTs_SC}
                                whitelistedIds={this.state.whitelistedIds}
                                mintedNftIds={this.state.mintedNftIds}
                                diplomaRecords={this.state.diplomaRecords}
                            />} 
                        />
                        <Route path='/nft' element={
                            <NFT 
                                recordsCount={this.state.recordsCount}
                                NFTs_SCaddress={this.state.NFTs_SCaddress}
                                NFTs_SC={this.state.NFTs_SC}
                            />} 
                        />
                        <Route path='/track' element={
                            <Track 
                                UDRs_SCaddress={this.state.UDRs_SCaddress}
                                NFTs_SCaddress={this.state.NFTs_SCaddress}
                            />} 
                        />
                    </Routes>
                }
            </>
        );
    }
}

export default App;
