import React, { Component, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import SHA256 from '../cryptography/SHA-256';

const ethUtil = require('ethereumjs-util');
const ipfsClient = require('ipfs-http-client');
const projectId = '2Ifr9EIaPuGVUBdEvJgJBZehX2s';
const projectSecret = '85605066b216364af15a28e46d790620';
const auth = 'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64');

const ipfs = ipfsClient({
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    headers: {
        authorization: auth,
    },
});

class Home extends Component {
    constructor(props) {
        super(props);
        this.previewImage = this.previewImage.bind(this);
    }

    state = {
        isOpen: false,
        valFaculty: '',
        valDepartment: '',
        valMajor: '',
    };
    dialog_title = "Dialog Title";
    dialog_content = "Dialog Content";

    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    // The forwardRef is important!!
    // Dropdown needs access to the DOM node in order to position the Menu
    CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <>
            <a
                href="" ref={ref} className='dropdown'
                onClick={(e) => { e.preventDefault(); onClick(e); }}
            >
                {children} {
                    children === "Fakultas:" && <p className='valFaculty'>{this.state.valFaculty}</p>
                    ||
                    children === "Departemen:" && <p className='valDepartment'>{this.state.valDepartment}</p>
                    ||
                    children === "Program Studi:" && <p className='valMajor'>{this.state.valMajor}</p>
                }
                <b> &#xbb;</b>
            </a>
        </>
    ));

    // Dropdown needs access to the DOM of the Menu to measure it
    CustomMenu = React.forwardRef(
        ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
            const [filter, setFilter] = useState('');

            return (
                <div
                    ref={ref} style={style} className={className} aria-labelledby={labeledBy}
                >
                    <FormControl
                        className="mx-3 my-2 w-auto"
                        placeholder="Type to filter..."
                        onChange={(e) => setFilter(e.target.value)}
                        value={filter}
                    />
                    <ul className="list-unstyled">
                        {React.Children.toArray(children).filter(
                        (child) =>
                            !filter || child.props.children.startsWith(filter) || child.props.children.toLowerCase().startsWith(filter) || child.props.children.toUpperCase().startsWith(filter),
                        )}
                    </ul>
                </div>
            );
        },
    );

    previewImage (event) {
        event.preventDefault();
        const image = document.querySelector('#image');
        const imgPreview = document.querySelector('.img-preview');

        imgPreview.style.display = 'block';

        if(image.files[0]){
            const oFReader = new FileReader();
            oFReader.readAsDataURL( image.files[0] );
            
            oFReader.onload = function (oFREvent) {
                imgPreview.src = oFREvent.target.result;
            }
        }
    }

    captureFile = event => {
        event.preventDefault();

        if(event.target.files[0]){
            const file = event.target.files[0];
            const reader = new window.FileReader();
            reader.readAsArrayBuffer(file);
    
            reader.onloadend = () => {
                this.setState({ buffer: Buffer(reader.result) });
                console.log('buffer : ', this.state.buffer);
            }
        }
    }

    uploadImage = async (_NIM, _studentName, _studentAddress, _major, _department, _faculty, _graduationYear) => {
        const isAdmin = await this.props.UDRs_SC.methods.owner().call();
        const activeAccount = this.props.activeAccount;

        if(isAdmin === activeAccount){
            console.log('Submitting file to ipfs');
            try {
                this.setState({ loading: true });
                this.dialog_title = 'Silahkan Tunggu';
                this.dialog_content = 'Gambar sedang diproses untuk upload ke IPFS...';
                this.setState({ isOpen: true });
    
                // Adding image file to the IPFS
                const result = await ipfs.add(this.state.buffer);
                const _hashImage = await result[0].path;
                console.log('Ipfs result', result);
    
                if (_hashImage.length > 0) {
                    let isHashImageExist = false;
    
                    // mengecek hash file yang akan diupload, apakah telah diunggah sebelumnya atau belum
                    this.props.diplomaRecords.map((diplomaRecord) => {
                        if(_hashImage === diplomaRecord.hashImage){
                            isHashImageExist = true;
                        }
                    });
    
                    if(isHashImageExist){
                        this.setState({ loading: false });
                        this.dialog_title = 'Gagal';
                        this.dialog_content = `Upload file gambar ke IPFS gagal, tidak dapat mengunggah hash ${result[0].path} yang sama sehingga terjadi duplikasi file ijazah.`;
                        this.setState({ isOpen: true });
                    }else{
                        this.props.UDRs_SC.methods.publishData( _NIM, _studentName, _studentAddress, _major, _department, _faculty, _graduationYear, _hashImage ).send({ from: activeAccount }).on('transactionHash', (hash) => {
                            this.setState({ loading: false });
                            this.dialog_title = 'Berhasil';
                            this.dialog_content = `Upload file gambar ke IPFS telah sukses dengan hash ${result[0].path}, sekaligus data sukses disimpan ke Blockchain dengan bukti hash transaksi ${hash}.`;
                            this.setState({ isOpen: true });
                        });
                    }
                }
            } catch(error) {
                console.log(error.message);
            }
        }else{
            this.setState({ loading: false });
            this.dialog_title = 'Gagal';
            this.dialog_content = `Hanya admin yang dapat mengunggah data mahasiswa!`;
            this.setState({ isOpen: true });
        }
    }

    // mengambil nilai ketika form masukan diisi & mengembalikan hash sebagai Id Internal Ijazah secara real-time
    get_value() {
        const _NIM = document.getElementsByName('NIM')[0].value;
        const _studentName = document.getElementsByName('studentName')[0].value;
        const _major = document.getElementsByClassName('valMajor')[0].innerText;
        const _department = document.getElementsByClassName('valDepartment')[0].innerText;
        const _faculty = document.getElementsByClassName('valFaculty')[0].innerText;
        const _graduationYear = document.getElementsByName('graduationYear')[0].value;
        const plain_values = _NIM+_studentName+_major+_department+_faculty+_graduationYear;
        
        return plain_values;
    }

    // Generate Private Key
    generatePrivateKey() {
        let plain_values = this.get_value();
        let privateKey = SHA256(plain_values);
        console.log(`secret private key: ${privateKey}`);

        return document.getElementsByName('privateKey')[0].value = privateKey;
    }

    // Generate Wallet Address from Private Key
    generateWalletAddress() {
        let privateKey = this.generatePrivateKey();
        let privateKeyBuffer, addressBuffer, walletAddress = '0x0000000000000000000000000000000000000000';

        if(this.get_value().length > 0){
            privateKeyBuffer = Buffer.from(privateKey, 'hex');
            addressBuffer = ethUtil.privateToAddress(privateKeyBuffer);
            walletAddress = `0x${addressBuffer.toString('hex')}`;
        }

        return document.getElementsByName('studentAddress')[0].value = walletAddress;
    }

    render() {
        return (
            <>
                <Container className='my-4'>
                    <Modal
                        show={this.state.isOpen}
                        onHide={this.closeModal}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{this.dialog_title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p className='text-justify'>{this.dialog_content}</p>
                        </Modal.Body>
                    </Modal>
                    
                    <Form onSubmit={(event) => {
                        event.preventDefault();
                        const _NIM = this.NIM.value;
                        const _studentName = this.studentName.value;
                        const _studentAddress = this.generateWalletAddress();
                        const _major = this.state.valMajor;
                        const _department = this.state.valDepartment;
                        const _faculty = this.state.valFaculty;
                        const _graduationYear = this.graduationYear.value;

                        this.uploadImage(_NIM, _studentName, _studentAddress, _major, _department, _faculty, _graduationYear);
                    }}>
                        <Form.Group controlId="formBasicStudentAddress">
                            <Col sm={12} md={8} lg={6} className='mx-auto'>
                                <Form.Label>Id Ijazah Internal</Form.Label>
                            </Col>
                            <Col sm={12} md={8} lg={6} className='mx-auto'>
                                <Form.Control type="text" placeholder="Id Ijazah Internal & Wallet Address" pattern=".{15,}" title="15 characters minimum" name="studentAddress" ref={(input3) => { this.studentAddress = input3 }} value={this.generateWalletAddress} required disabled />
                            </Col>
                        </Form.Group>
                        <Form.Group controlId="formBasicNIM">
                            <Col sm={12} md={8} lg={6} className='mx-auto'>
                                <Form.Label>NIM</Form.Label>
                            </Col>
                            <Col sm={12} md={8} lg={6} className='mx-auto'>
                                <Form.Control type="number" placeholder="Masukan NIM" min="1" name="NIM" ref={(input1) => { this.NIM = input1 }} onChange={e => {this.generateWalletAddress()}} required autoFocus />
                            </Col>
                        </Form.Group>
                        <Form.Group controlId="formBasicStudentName">
                            <Col sm={12} md={8} lg={6} className='mx-auto'>
                                <Form.Label>Nama Mahasiswa</Form.Label>
                            </Col>
                            <Col sm={12} md={8} lg={6} className='mx-auto'>
                                <Form.Control type="text" placeholder="Masukan nama mahasiswa" name="studentName" ref={(input2) => { this.studentName = input2 }} onChange={e => {this.generateWalletAddress()}} required />
                            </Col>
                        </Form.Group>

                        <Form.Group controlId="formBasicFaculty">
                            <Col sm={12} md={8} lg={6} className='mx-auto'>
                                <Dropdown drop='right'>
                                    <Dropdown.Toggle as={this.CustomToggle} id="dropdown-custom-components">
                                        Fakultas:
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu as={this.CustomMenu}>
                                    {['FPEB', 'FIP', 'FPIPS', 'FPMIPA', 'FPSD', 'FPTK', 'FPBS', 'FPOK'].map(
                                        (faculty, i) => (
                                            <Dropdown.Item eventKey={i} className={this.state.valFaculty === `${faculty}` ? 'active' : ''} onClick={() => this.setState({valFaculty: `${faculty}`})} onChange={e => { this.CustomMenu(e); this.generateWalletAddress() }}>{faculty}</Dropdown.Item>
                                        )
                                    )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        </Form.Group>
                        <Form.Group controlId="formBasicDepartment">
                            <Col sm={12} md={8} lg={6} className='mx-auto'>
                                <Dropdown drop='right'>
                                    <Dropdown.Toggle as={this.CustomToggle} id="dropdown-custom-components">
                                        Departemen:
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu as={this.CustomMenu}>
                                    {
                                        this.state.valFaculty === 'FPEB' ?
                                        ['Departemen Manajemen', 'Departemen Pendidikan Bisnis'].map(
                                            (department, i) =>  (
                                                <Dropdown.Item eventKey={i} className={this.state.valDepartment === `${department}` ? 'active' : ''} onClick={() => this.setState({valDepartment: `${department}`})} onChange={e => { this.CustomMenu(e); this.generateWalletAddress() }}>{department}</Dropdown.Item>
                                            )
                                        )
                                        :
                                        this.state.valFaculty === 'FIP' ?
                                        ['Departemen Administrasi Pendidikan', 'Departemen Pendidikan Khusus', 'Departemen Pendidikan Psikologi'].map(
                                            (department, i) => (
                                                <Dropdown.Item eventKey={i} className={this.state.valDepartment === `${department}` ? 'active' : ''} onClick={() => this.setState({valDepartment: `${department}`})} onChange={e => { this.CustomMenu(e); this.generateWalletAddress() }}>{department}</Dropdown.Item>
                                            )
                                        )
                                        :
                                        this.state.valFaculty === 'FPIPS' ?
                                        ['Departemen Pendidikan Geografi', 'Departemen Pendidikan Sosiologi'].map(
                                            (department, i) => (
                                                <Dropdown.Item eventKey={i} className={this.state.valDepartment === `${department}` ? 'active' : ''} onClick={() => this.setState({valDepartment: `${department}`})} onChange={e => { this.CustomMenu(e); this.generateWalletAddress() }}>{department}</Dropdown.Item>
                                            )
                                        )
                                        :
                                        this.state.valFaculty === 'FPMIPA' ?
                                        ['Departemen SA-IPSE', 'Departemen Pendidikan Ilmu Komputer'].map(
                                            (department, i) => (
                                                <Dropdown.Item eventKey={i} className={this.state.valDepartment === `${department}` ? 'active' : ''} onClick={() => this.setState({valDepartment: `${department}`})} onChange={e => { this.CustomMenu(e); this.generateWalletAddress() }}>{department}</Dropdown.Item>
                                            )
                                        )
                                        :
                                        this.state.valFaculty === 'FPSD' ?
                                        ['Departemen Pendidikan Seni'].map(
                                            (department, i) =>  (
                                                <Dropdown.Item eventKey={i} className={this.state.valDepartment === `${department}` ? 'active' : ''} onClick={() => this.setState({valDepartment: `${department}`})} onChange={e => { this.CustomMenu(e); this.generateWalletAddress() }}>{department}</Dropdown.Item>
                                            )
                                        )
                                        :
                                        this.state.valFaculty === 'FPTK' ?
                                        ['Departemen Pendidikan Kesejahteraan Keluarga', 'Departemen Pendidikan Teknik Arsitektur', 'Departemen Pendidikan Teknik Elektro', 'Departemen Pendidikan Teknik Mesin', 'Departemen Pendidikan Teknik Sipil'].map(
                                            (department, i) => (
                                                <Dropdown.Item eventKey={i} className={this.state.valDepartment === `${department}` ? 'active' : ''} onClick={() => this.setState({valDepartment: `${department}`})} onChange={e => { this.CustomMenu(e); this.generateWalletAddress() }}>{department}</Dropdown.Item>
                                            )
                                        )
                                        :
                                        this.state.valFaculty === 'FPBS' ?
                                        ['Departemen Pendidikan Bahasa Daerah', 'Departemen Pendidikan Bahasa Arab', 'Departemen Pendidikan Bahasa Inggris', 'Departemen Pendidikan Bahasa dan Sastra Indonesia'].map(
                                            (department, i) => (
                                                <Dropdown.Item eventKey={i} className={this.state.valDepartment === `${department}` ? 'active' : ''} onClick={() => this.setState({valDepartment: `${department}`})} onChange={e => { this.CustomMenu(e); this.generateWalletAddress() }}>{department}</Dropdown.Item>
                                            )
                                        )
                                        :
                                        this.state.valFaculty === 'FPOK' ?
                                        ['Departemen Pendidikan Olahraga', 'Departemen Pendidikan Kesehatan dan Rekreasi'].map(
                                            (department, i) => (
                                                <Dropdown.Item eventKey={i} className={this.state.valDepartment === `${department}` ? 'active' : ''} onClick={() => this.setState({valDepartment: `${department}`})} onChange={e => { this.CustomMenu(e); this.generateWalletAddress() }}>{department}</Dropdown.Item>
                                            )
                                        )
                                        :
                                        ''
                                    }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        </Form.Group>
                        <Form.Group controlId="formBasicMajor">
                            <Col sm={12} md={8} lg={6} className='mx-auto'>
                                <Dropdown drop='right'>
                                    <Dropdown.Toggle as={this.CustomToggle} id="dropdown-custom-components">
                                        Program Studi:
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu as={this.CustomMenu}>
                                    {
                                        this.state.valFaculty === 'FPEB' ?
                                        ['Akuntansi', 'Ilmu Ekonomi dan Keuangan Islam', 'Manajemen', 'Pendidikan Akuntansi', 'Pendidikan Ekonomi', 'Pendidikan Ekonomi dan Koperasi', 'Pendidikan Manajemen Bisnis', 'Pendidikan Manajemen Perkantoran', 'Pendidikan Bisnis'].map(
                                            (major, i) =>  (
                                                <Dropdown.Item eventKey={i} className={this.state.valMajor === `${major}` ? 'active' : ''} onClick={() => this.setState({valMajor: `${major}`})} onChange={e => { this.CustomMenu(e); this.generateWalletAddress() }}>{major}</Dropdown.Item>
                                            )
                                        )
                                        :
                                        this.state.valFaculty === 'FIP' ?
                                        ['Administrasi Pendidikan', 'Pedagogik', 'PGPAUD Bumi Siliwangi', 'PGSD Bumi Siliwangi', 'Pendidikan Luar Biasa', 'Pendidikan Luar Sekolah', 'Pendidikan Psikologi dan Bimbingan', 'Program Studi Perpustakaan dan Informasi', 'Program Studi Psikologi', 'Teknologi Pendidikan', 'Bimbingan dan Konseling', 'Pendidikan Anak Usia Dini', 'Pendidikan Masyarakat'].map(
                                            (major, i) => (
                                                <Dropdown.Item eventKey={i} className={this.state.valMajor === `${major}` ? 'active' : ''} onClick={() => this.setState({valMajor: `${major}`})} onChange={e => { this.CustomMenu(e); this.generateWalletAddress() }}>{major}</Dropdown.Item>
                                            )
                                        )
                                        :
                                        this.state.valFaculty === 'FPIPS' ?
                                        ['Ilmu Komunikasi', 'Manajemen Industri Katering', 'Manajemen Pemasaran Pariwisata', 'Manajemen Resort & Leisure', 'Pendidikan Agama Islam', 'Pendidikan IPS', 'Pendidikan Kewarganegaraan', 'Pendidikan Sejarah', 'Kepariwisataan', 'Sosiologi', 'Pendidikan Geografi', 'Sains Informasi Geografi'].map(
                                            (major, i) => (
                                                <Dropdown.Item eventKey={i} className={this.state.valMajor === `${major}` ? 'active' : ''} onClick={() => this.setState({valMajor: `${major}`})} onChange={e => { this.CustomMenu(e); this.generateWalletAddress() }}>{major}</Dropdown.Item>
                                            )
                                        )
                                        :
                                        this.state.valFaculty === 'FPMIPA' ?
                                        ['International Program on Science Education', 'Biologi', 'Pendidikan Biologi', 'Fisika', 'Pendidikan Fisika', 'Kimia', 'Pendidikan Kimia', 'Matematika', 'Pendidikan Matematika', 'Ilmu Komputer', 'Pendidikan Ilmu Komputer'].map(
                                            (major, i) => (
                                                <Dropdown.Item eventKey={i} className={this.state.valMajor === `${major}` ? 'active' : ''} onClick={() => this.setState({valMajor: `${major}`})} onChange={e => { this.CustomMenu(e); this.generateWalletAddress() }}>{major}</Dropdown.Item>
                                            )
                                        )
                                        :
                                        this.state.valFaculty === 'FPSD' ?
                                        ['Pendidikan Seni Musik', 'Pendidikan Seni Rupa dan Kerajinan', 'Pendidikan Seni Tari'].map(
                                            (major, i) =>  (
                                                <Dropdown.Item eventKey={i} className={this.state.valMajor === `${major}` ? 'active' : ''} onClick={() => this.setState({valMajor: `${major}`})} onChange={e => { this.CustomMenu(e); this.generateWalletAddress() }}>{major}</Dropdown.Item>
                                            )
                                        )
                                        :
                                        this.state.valFaculty === 'FPTK' ?
                                        ['Pendidikan Tata Boga', 'Pendidikan Kesejahteraan Keluarga', 'Pendidikan Tata Busana', 'Pendidikan Teknologi Agroindustri', 'Pendidikan Teknik Arsitektur', 'Pendidikan Teknik Elektro', 'Teknik Tenaga Elektrik', 'Pendidikan Teknik Mesin', 'Pendidikan Teknik Bangunan', 'Teknik Sipil', 'Pendidikan Teknik Otomotif'].map(
                                            (major, i) => (
                                                <Dropdown.Item eventKey={i} className={this.state.valMajor === `${major}` ? 'active' : ''} onClick={() => this.setState({valMajor: `${major}`})} onChange={e => { this.CustomMenu(e); this.generateWalletAddress() }}>{major}</Dropdown.Item>
                                            )
                                        )
                                        :
                                        this.state.valFaculty === 'FPBS' ?
                                        ['Pendidikan Bahasa Arab', 'Bahasa dan Sastra Inggris', 'Pendidikan Bahasa Inggris', 'Pendidikan Bahasa Jepang', 'Pendidikan Bahasa Jerman', 'Pendidikan Bahasa Korea', 'Pendidikan Bahasa Prancis', 'Bahasa dan Sastra Indonesia', 'Pendidikan Bahasa dan Sastra Indonesia', 'Pendidikan Seni Musik', 'Pendidikan Seni Rupa', 'Pendidikan Seni Tari', 'Pendidikan Bahasa Daerah'].map(
                                            (major, i) => (
                                                <Dropdown.Item eventKey={i} className={this.state.valMajor === `${major}` ? 'active' : ''} onClick={() => this.setState({valMajor: `${major}`})} onChange={e => { this.CustomMenu(e); this.generateWalletAddress() }}>{major}</Dropdown.Item>
                                            )
                                        )
                                        :
                                        this.state.valFaculty === 'FPOK' ?
                                        ['Pendidikan Kepelatihan', 'IKOR', 'PGSD Penjas', 'Pendidikan Jasmani, Kesehatan dan Rekreasi'].map(
                                            (major, i) => (
                                                <Dropdown.Item eventKey={i} className={this.state.valMajor === `${major}` ? 'active' : ''} onClick={() => this.setState({valMajor: `${major}`})} onChange={e => { this.CustomMenu(e); this.generateWalletAddress() }}>{major}</Dropdown.Item>
                                            )
                                        )
                                        :
                                        ''
                                    }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        </Form.Group>

                        <Form.Group controlId="formBasicGraduationYear">
                            <Col sm={12} md={8} lg={6} className='mx-auto'>
                                <Form.Label>Tahun Lulus</Form.Label>
                            </Col>
                            <Col sm={12} md={8} lg={6} className='mx-auto'>
                                <Form.Control type="number" placeholder="Masukan tahun lulus" min="1950" name="graduationYear" ref={(input7) => { this.graduationYear = input7 }} onChange={e => {this.generateWalletAddress()}} required />
                            </Col>
                        </Form.Group>
                        <Form.Group controlId="formBasicPrivateKey">
                            <Col sm={12} md={8} lg={6} className='mx-auto'>
                                <Form.Label>Secret Private Key Mahasiswa</Form.Label>
                            </Col>
                            <Col sm={12} md={8} lg={6} className='mx-auto'>
                                <Form.Control type="password" placeholder="Student Wallet Address Private Key" name="privateKey" value={this.generatePrivateKey} disabled />
                            </Col>
                        </Form.Group>
                        <Form.Group controlId="formBasicGraduationYear">
                            <Col sm={12} md={8} lg={6} className='mx-auto'>
                                <Form.Label>Upload Ijazah</Form.Label>
                            </Col>
                            <Col sm={12} md={8} lg={6} className='mx-auto'>
                                <img src="" alt="" class="img-preview img-fluid mb-3 col-sm-5" />
                                <Form.Control type="file" placeholder="Masukan file (img) ijazah" id="image" accept="image/*" onChange={(e) => { this.captureFile(e); this.previewImage(e); }} required />
                            </Col>
                        </Form.Group>

                        <Col sm={12} md={8} lg={6} className='mx-auto'>
                            <Button variant="secondary" type="submit">
                                Submit
                            </Button>
                        </Col>
                    </Form>
                </Container>
            </>
        );
    }
}

export default Home;

/*
    note sementara hasil revisi [18 Feb 2024]
    uji publish & mint hasil: 
    udr_sc: 0x688eefbc5b1f9d48b07976d04dadb902e83e2902
    nft_sc: 0x2930f7149416445cb2378ff5269cbbd13d2ae890
*/
