import React, { Component } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Identicon from 'identicon.js';

class NavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navStatus: null,
        };
    }

    render() {
        return (
            <Navbar collapseOnSelect expand="md" bg="dark" variant="dark" sticky="top">
                <Container>
                    <Navbar.Brand href="/">Simulation</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link><Link to='/' className={this.state.navStatus === 'home' ? 'active' : ''} onClick={() => this.setState({navStatus: "home"})}>Home</Link></Nav.Link>
                            <Nav.Link><Link to='/data' className={this.state.navStatus === 'data' ? 'active' : ''} onClick={() => this.setState({navStatus: "data"})}>Data</Link></Nav.Link>
                            <Nav.Link><Link to='/nft' className={this.state.navStatus === 'nft' ? 'active' : ''} onClick={() => this.setState({navStatus: "nft"})}>NFT</Link></Nav.Link>
                            <Nav.Link><Link to='/track' className={this.state.navStatus === 'track' ? 'active' : ''} onClick={() => this.setState({navStatus: "track"})}>Track</Link></Nav.Link>
                            <Nav.Link><Link to='/about' className={this.state.navStatus === 'about' ? 'active' : ''} onClick={() => this.setState({navStatus: "about"})}>About</Link></Nav.Link>
                        </Nav>
                        <Nav>
                            <Nav.Link href='' className='mr-2'>{this.props.account}</Nav.Link>
                            { this.props.account
                            ? <img
                                width='30'
                                height='30'
                                src={`data:image/png;base64,${new Identicon(this.props.account, 30).toString()}`}
                            />
                            : <Nav.Link href=''>[not connected]</Nav.Link>
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    }
}

export default NavBar;
