import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Identicon from 'identicon.js';

const ipfsClient = require('ipfs-http-client');
const projectId = '2Ifr9EIaPuGVUBdEvJgJBZehX2s';
const projectSecret = '85605066b216364af15a28e46d790620';
const auth = 'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64');

const ipfs = ipfsClient({
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    headers: {
        authorization: auth,
    },
});

class NFT extends Component {
    constructor(props) {
        super(props);
        this.state = {
            metadataNFTs: [],
        }
    }

    state = {
        isOpen: false
    };
    dialog_title = "Dialog Title";
    dialog_content = "Dialog Content";
    dialog_openseaLink = "Dialog Opensea Link";
    dialog_metadataLink = "Dialog Metadata Link";
    dialog_trackLink = "Dialog Track Link";

    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    async componentDidMount() {
        await this.offChainNFTsData();
    }

    offChainNFTsData = async () => {
        this.setState({ loading: true });
        let metadataHash = [];
        // get all IPFS CID from on-chain NFTs_SC
        for(let i = 1; i <= this.props.recordsCount; ++i){
            try {
                let tokenURIs = await this.props.NFTs_SC.methods.tokenURI(i).call();

                metadataHash.push(await tokenURIs.slice(-46));
            } catch(error) {
                console.log(error);
            }
        }
        // console.log('recordsCount: ' + this.props.recordsCount);
        // console.log(metadataHash);

        let allJsonData = [];
        let jsonData = null;
        let ipfsCat1 = [];
        let ipfsCat2 = [];

        // convert each IPFS CID to object
        metadataHash.map((cid) => {
            // get & store off chain nfts metadata
            ipfsCat1 = ipfs.cat(cid)
                .then(data => {
                    jsonData = JSON.parse(data.toString());
                    
                    allJsonData.push(jsonData);
                })
                .catch(error => {
                    console.error('Error retrieving JSON data from IPFS:', error);
                }
            );
        });
        ipfsCat2 = await ipfsCat1;

        this.setState({ 
            metadataNFTs: allJsonData, 
            loading: false,
        });
    }
    
    detailOffChainNFTsData = async (event, mtdtNFT) => {
        event.preventDefault();
        const _mtdtNFT = JSON.stringify(mtdtNFT, (_, v) => typeof v === 'bigint' ? v.toString() : v);
        const result = await ipfs.add(Buffer.from(_mtdtNFT));

        this.dialog_title = "About NFT";
        this.dialog_content = mtdtNFT.description;
        this.dialog_openseaLink = `https://opensea.io/assets/matic/${this.props.NFTs_SCaddress}/${mtdtNFT.attributes[0].value}`;
        this.dialog_metadataLink = `https://certifaw.infura-ipfs.io/ipfs/${result[0].path}`;
        this.dialog_trackLink = `https://polygonscan.com/nft/${this.props.NFTs_SCaddress}/${mtdtNFT.attributes[0].value}`;
        this.setState({ isOpen: true });
    }

    render() {
        return (
            <>
                <Container className='my-4'>
                    <Modal
                        show={this.state.isOpen}
                        onHide={this.closeModal}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{this.dialog_title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p className='text-justify'>{this.dialog_content}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <a href={`${this.dialog_openseaLink}`} target='blank'>
                                <Button variant="secondary">See in OpenSea</Button>
                            </a>
                            <a href={`${this.dialog_metadataLink}`} target='blank'>
                                <Button variant="secondary">See Metadata</Button>
                            </a>
                            <a href={`${this.dialog_trackLink}`} target='blank'>
                                <Button variant="secondary">Track</Button>
                            </a>
                        </Modal.Footer>
                    </Modal>
                    
                    <Col sm={12} md={8} lg={6} className='mx-auto'>
                        <h1 className='h4 mb-3'>Galeri NFT</h1>

                        {this.state.metadataNFTs.length === 0
                        ?
                            <h1 className='h5 mb-4'>Belum terdapat NFT ijazah, silahkan cetak pada halaman Data.</h1>
                        :
                            this.state.metadataNFTs.map((mtdtNFT, key) => {
                                return (
                                    <Form onSubmit={(event) => {
                                        event.preventDefault();
                                        var id = parseInt(mtdtNFT.id);
                                        this.handleMinting(id);
                                    }}>
                                        <div className='card mt-4 mb-4' key={key}>
                                            <div className='card-header'>
                                                <img className='mr-2' width='30' height='30' src={`data:image/png;base64,${new Identicon(mtdtNFT.attributes[3].value, 30).toString()}`} />
                                                <small className='text-muted'>{mtdtNFT.attributes[3].value}</small>
                                            </div>
                                            <div className="card-body">
                                                <a href={mtdtNFT.image} className='text-center' target='_blank'>
                                                    <Col><img src={mtdtNFT.image} style={{ maxWidth: '25vmin', padding: '10px 0'}} /></Col>
                                                </a>
                                                <Row>
                                                    <Col sm={12}>
                                                        <p className='h6 text-center mb-0'>{mtdtNFT.attributes[1].value} - {mtdtNFT.attributes[2].value}</p>
                                                    </Col>
                                                </Row>
                                                <Row className='mt-3'>
                                                    <Col align="center" xs={12} md={12}>
                                                        <Button 
                                                            variant="secondary"
                                                            type="button"
                                                            onClick={(event) => {this.detailOffChainNFTsData(event, mtdtNFT)}}
                                                        >
                                                            See Detail
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Form>
                                );
                            })
                        }
                    </Col>
                </Container>
            </>
        );
    }
}

export default NFT;
