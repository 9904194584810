import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

class Track extends Component {
    constructor(props) {
        super(props);
        this.state = {
            networkId: 0,
            linkExplorer: '',
        }
    }

    async componentDidMount() {
        await this.loadBlockchainData();
    }

    async loadBlockchainData() {
        const web3 = window.web3;
        const networkId = await web3.eth.net.getId();

        if(networkId === 137){
            // main network id
            this.setState({ linkExplorer: 'https://polygonscan.com' });
        }else if(networkId === 80001){
            // test network id
            this.setState({ linkExplorer: 'https://mumbai.polygonscan.com' });
        }else{
            window.alert('University Diploma Records contract not deployed to detected network!');
        }
    }

    getSet_tract_value1() {
        let _publisherWalletAddress1get = document.getElementsByName('publisherWalletAddress1get')[0].value;
        return document.getElementById("publisherWalletAddress2set").href = `${this.state.linkExplorer}/address/${_publisherWalletAddress1get}`;
    }
    getSet_tract_value2() {
        let _recordTransactionHash1get = document.getElementsByName('recordTransactionHash1get')[0].value;
        return document.getElementById("recordTransactionHash2set").href = `${this.state.linkExplorer}/tx/${_recordTransactionHash1get}`;
    }
    getSet_tract_value3() {
        let _IPFSHash1get = document.getElementsByName('IPFSHash1get')[0].value;
        return document.getElementById("IPFSHash2set").href = `https://certifaw.infura-ipfs.io/ipfs/${_IPFSHash1get}`;
    }
    
    render() {
        return (
            <>
                <Container className='my-4'>
                    <Col sm={12} md={8} lg={6} className='mx-auto'>
                        <Form>
                            <Form.Label>University Contract Address</Form.Label>
                            <InputGroup className="mb-4">
                                <FormControl
                                    aria-label="University Contract Address"
                                    aria-describedby="basic-addon2"
                                    value={this.props.UDRs_SCaddress}
                                    disabled
                                />
                                <InputGroup.Append>
                                    <a href={`${this.state.linkExplorer}/address/${this.props.UDRs_SCaddress}`} target='_blank'><Button variant="outline-secondary">Track</Button></a>
                                </InputGroup.Append>
                            </InputGroup>
                            
                            <Form.Label>NFT Contract Address</Form.Label>
                            <InputGroup className="mb-4">
                                <FormControl
                                    aria-label="NFT Contract Address"
                                    aria-describedby="basic-addon2"
                                    value={this.props.NFTs_SCaddress}
                                    disabled
                                />
                                <InputGroup.Append>
                                    <a href={`${this.state.linkExplorer}/address/${this.props.NFTs_SCaddress}`} target='_blank'><Button variant="outline-secondary">Track</Button></a>
                                </InputGroup.Append>
                            </InputGroup>

                            <Form.Label>Publisher Wallet Address</Form.Label>
                            <InputGroup className="mb-4">
                                <FormControl
                                    placeholder='Masukan Alamat Dompet Penerbit ("0x...")'
                                    aria-label="Publisher Wallet Address"
                                    aria-describedby="basic-addon2"
                                    name="publisherWalletAddress1get"
                                    onChange={this.getSet_tract_value1}
                                />
                                <InputGroup.Append>
                                    <a href='' id='publisherWalletAddress2set' target='_blank'><Button variant="outline-secondary">Track</Button></a>
                                </InputGroup.Append>
                            </InputGroup>

                            <Form.Label>Record Transaction Hash</Form.Label>
                            <InputGroup className="mb-4">
                                <FormControl
                                    placeholder='Masukan Hash Rekam Transaksi Ijazah ("0x...")'
                                    aria-label="Record Transaction Hash"
                                    aria-describedby="basic-addon2"
                                    name="recordTransactionHash1get"
                                    onChange={this.getSet_tract_value2}
                                />
                                <InputGroup.Append>
                                    <a href='' id='recordTransactionHash2set' target='_blank'><Button variant="outline-secondary">Track</Button></a>
                                </InputGroup.Append>
                            </InputGroup>

                            <Form.Label>IPFS Hash</Form.Label>
                            <InputGroup className="mb-4">
                                <FormControl
                                    placeholder='Masukan hash IPFS gambar ijazah / metadata NFT ("Qm...")'
                                    aria-label="IPFS Hash"
                                    aria-describedby="basic-addon2"
                                    name="IPFSHash1get"
                                    onChange={this.getSet_tract_value3}
                                />
                                <InputGroup.Append>
                                    <a href='' id='IPFSHash2set' target='_blank'><Button variant="outline-secondary">Track</Button></a>
                                </InputGroup.Append>
                            </InputGroup>
                        </Form>
                    </Col>
                </Container>
            </>
        );
    }
}

export default Track;
