import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Identicon from 'identicon.js';

const ipfsClient = require('ipfs-http-client');
const projectId = '2Ifr9EIaPuGVUBdEvJgJBZehX2s';
const projectSecret = '85605066b216364af15a28e46d790620';
const auth = 'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64');

const ipfs = ipfsClient({
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    headers: {
        authorization: auth,
    },
});

class Data extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        isOpen: false
    };
    dialog_title = "Dialog Title";
    dialog_content = "Dialog Content";

    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });
    
    handleWhitelist = async (event, _whitelistId, _studentAddress) => {
        event.preventDefault();
        const isAdmin = await this.props.NFTs_SC.methods.owner().call();
        const activeAccount = this.props.activeAccount;
        const isWhitelisted = await this.props.NFTs_SC.methods.whitelistedAddresses(_studentAddress).call();
        this.setState({ loading: true });
        
        if(isAdmin === activeAccount){
            if(isWhitelisted){
                this.setState({ loading: false });
                this.dialog_title = 'Gagal';
                this.dialog_content = 'Alamat wallet mahasiswa telah terdaftar di whitelist!';
                this.setState({ isOpen: true });
            }else{
                this.props.NFTs_SC.methods.addUser( _whitelistId, _studentAddress ).send({ from: this.props.activeAccount }).on('transactionHash', (hash) => {
                    this.setState({ loading: false });
                    this.dialog_title = 'Berhasil';
                    this.dialog_content = `Alamat wallet mahasiswa telah sukses ditambahkan ke daftar whitelist dengan bukti hash transaksi ${hash}.`;
                    this.setState({ isOpen: true });
                });
            }
        }else{
            this.setState({ loading: false });
            this.dialog_title = 'Gagal';
            this.dialog_content = `Hanya admin yang dapat menambahkan wallet address mahasiswa ke whitelist!`;
            this.setState({ isOpen: true });
        }
    }

    handleMinting = async (uid) => {
        const onChainData = [];

        this.props.diplomaRecords.forEach((oc_data) => {
            if(uid === oc_data.id.toNumber()){
                onChainData.push(oc_data);
            }
        });

        const data = onChainData[0];
        const nftMetadata = {
            "name": `${data.studentName} #${data.id.toNumber()}`,
            "description": `Data ijazah mahasiswa ${data.studentName} dengan nomor ijazah internal yang sekaligus menjadi pemilik alamat dompet ${data.internalDiplomaId}, telah berhasil mendapatkan kepemilikan aset digital berupa ijazah NFT dengan nomor id ${data.id.toNumber()} pada alamat kontrak pintar ${this.props.NFTs_SCaddress}. Ijazah NFT tersebut dapat diimpor ke dompet MetaMask mahasiswa, namun tidak dapat diperjualbelikan atau ditransaksikan.`,
            "image": `https://certifaw.infura-ipfs.io/ipfs/${data.hashImage}`,
            "attributes": [
                { 'trait_type': 'id', 'value': data.id.toNumber() },
                { 'trait_type': 'nim', 'value': data.NIM.toNumber() },
                { 'trait_type': 'student_name', 'value': data.studentName },
                { 'trait_type': 'student_address', 'value': data.internalDiplomaId },
                { 'trait_type': 'major', 'value': data.major },
                { 'trait_type': 'department', 'value': data.department },
                { 'trait_type': 'faculty', 'value': data.faculty },
                { 'trait_type': 'graduation_year', 'value': data.graduationYear.toNumber() },
                { 'trait_type': 'image_hash', 'value': data.hashImage },
            ],
        };
        const nftMtdt = JSON.stringify(nftMetadata, (_, v) => typeof v === 'bigint' ? v.toString() : v);
        const isAdmin = await this.props.NFTs_SC.methods.owner().call();
        const activeAccount = this.props.activeAccount;

        if(isAdmin === activeAccount){
            try {
                const _tokenId = data.id;
                const _to = data.internalDiplomaId;
                const isWhitelisted = await this.props.NFTs_SC.methods.whitelistedAddresses( _to ).call();
                this.setState({ loading: true });

                if(isWhitelisted){
                    this.dialog_title = 'Silahkan Tunggu';
                    this.dialog_content = 'Proses minting data ijazah sedang diproses...';
                    this.setState({ isOpen: true });

                    // Adding metadata json file to the IPFS
                    const result = await ipfs.add(Buffer.from(nftMtdt));
                    const _uriIpfs = result[0].path;

                    if(_uriIpfs.length > 0){
                        this.props.NFTs_SC.methods.safeMint( _tokenId, _to, _uriIpfs ).send({ from: this.props.activeAccount }).on('transactionHash', (hash) => {
                            this.setState({ loading: false });
                            this.dialog_title = 'Berhasil';
                            this.dialog_content = `Upload metadata mahasiswa ke IPFS telah sukses dengan hash ${_uriIpfs}, sekaligus data sukses disimpan ke Blockchain dengan bukti hash transaksi ${hash}.`;
                            this.setState({ isOpen: true });
                        });
                    }
                }else{
                    this.setState({ loading: false });
                    this.dialog_title = 'Gagal';
                    this.dialog_content = 'Alamat wallet mahasiswa belum terdaftar di whitelist!';
                    this.setState({ isOpen: true });
                }
            } catch(error) {
                console.log(error.message);
            }
        }else{
            this.setState({ loading: false });
            this.dialog_title = 'Gagal';
            this.dialog_content = `Hanya admin yang dapat mencetak ijazah NFT mahasiswa!`;
            this.setState({ isOpen: true });
        }
    }

    render() {
        return (
            <>
                <Container className='my-4'>
                    <Modal
                        show={this.state.isOpen}
                        onHide={this.closeModal}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{this.dialog_title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p className='text-justify'>{this.dialog_content}</p>
                        </Modal.Body>
                    </Modal>
                    
                    <Col sm={12} md={8} lg={6} className='mx-auto'>
                        <h1 className='h4 mb-3'>Data On-chain</h1>

                        {this.props.diplomaRecords.length === 0
                        ?
                            <h1 className='h5 mb-4'>Belum tersedia data on-chain, silahkan tambahkan pada halaman Home.</h1>
                        :
                            this.props.diplomaRecords.map((diplomaRecord, key) => {
                                let mintedNftId = null;
                                this.props.mintedNftIds.forEach(nftId => {
                                    if(nftId.toNumber() === diplomaRecord.id.toNumber()){
                                        return mintedNftId = nftId;
                                    }
                                });
                                
                                let whitelistedId = null;
                                this.props.whitelistedIds.forEach(wl_id => {
                                    if(wl_id.toNumber() === diplomaRecord.id.toNumber()){
                                        return whitelistedId = wl_id;
                                    }
                                });

                                return (
                                    <Form onSubmit={(event) => {
                                        event.preventDefault();
                                        var id = parseInt(diplomaRecord.id);
                                        this.handleMinting(id);
                                    }}>
                                        <div className='card mt-4 mb-4' key={key}>
                                            <div className='card-header'>
                                                <img className='mr-2' width='30' height='30' src={`data:image/png;base64,${new Identicon(diplomaRecord.internalDiplomaId, 30).toString()}`} />
                                                <small className='text-muted'>{diplomaRecord.internalDiplomaId}</small>
                                            </div>
                                            <div className="card-body">
                                                <a href={`https://certifaw.infura-ipfs.io/ipfs/${diplomaRecord.hashImage}`} className='text-center' target='_blank'>
                                                    <Col><img src={`https://certifaw.infura-ipfs.io/ipfs/${diplomaRecord.hashImage}`} style={{ maxWidth: '25vmin', padding: '10px 0'}} /></Col>
                                                </a>
                                                <Row>
                                                    <Col sm={4}>NIM</Col>
                                                    <Col sm={8}>: {diplomaRecord.NIM.toNumber()}</Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={4}>Nama</Col>
                                                    <Col sm={8}>: {diplomaRecord.studentName}</Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={4}>Prodi</Col>
                                                    <Col sm={8}>: {diplomaRecord.major}</Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={4}>Departemen</Col>
                                                    <Col sm={8}>: {diplomaRecord.department}</Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={4}>Fakultas</Col>
                                                    <Col sm={8}>: {diplomaRecord.faculty}</Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={4}>Tahun Lulus</Col>
                                                    <Col sm={8}>: {diplomaRecord.graduationYear.toNumber()}</Col>
                                                </Row>
                                                {
                                                    mintedNftId === null
                                                    &&
                                                    <Row className='mt-3'>
                                                        <Col align="center" xs={12} md={12}>
                                                            <Button 
                                                                variant="secondary"
                                                                type="button"
                                                                className={whitelistedId ? 'mr-3 btn-mod-opa65' : 'mr-3'}
                                                                onClick={(event) => {this.handleWhitelist(event, diplomaRecord.id, diplomaRecord.internalDiplomaId)}}
                                                            >
                                                                Add to Whitelist
                                                            </Button>
                                                            <Button variant="secondary" type="submit" className={!whitelistedId && 'btn-mod-opa65'}>Mint NFT</Button>
                                                        </Col>
                                                    </Row>
                                                }
                                            </div>
                                        </div>
                                    </Form>
                                );
                            })
                        }
                    </Col>
                </Container>
            </>
        );
    }
}

export default Data;
