function sha256(ascii) {
    // untuk melakukan operasi rotasi ke kanan pada bilangan, dengan jumlah yang ditentukan
    function rightRotate(value, amount) {
        return (value >>> amount) | (value << (32 - amount));
    };
    
    let mathPow = Math.pow;
    // nilai maksimum dari sebuah kata 32-bit (jumlah -2.147.483.648 hingga 2.147.483.647)
    let maxWord = mathPow(2, 32);
    let asciiBitLength = ascii.length * 8;
    let i, j;
    // state hash awal yang akan diperbarui selama proses hashing
    let hash = [];
    // konstanta yang digunakan dalam proses hashing
    let k = [];
    // blok-blok pesan yang akan diproses
    let words = [];
    let result = '';
    let primeCounter = k.length;
    
    let isComposite = {};
    for (i = 2; primeCounter < 64; ++i) {
        if (!isComposite[i]) {
            for (j = 0; j < 313; j += i) {
                isComposite[j] = i;
            }
            // state hash awal
            hash[primeCounter] = (mathPow(i, 1/2)*maxWord) | 0;
            // konstanta bulat
            k[primeCounter] = (mathPow(i, 1/3)*maxWord) | 0;
            ++primeCounter;
        }
    }

    // escape sequence heksadesimal
    // pre-precessing pesan dengan menambah padding bit 1 di akhir pesan
    ascii += '\x80'; 
    // menambah padding bit 0, hingga panjang pesan menjadi kelipatan 512-bit
    while (ascii.length % 64 - 56) ascii += '\x00'; 
    for (i = 0; i < ascii.length; ++i) {
        j = ascii.charCodeAt(i);
        // menggabungkan blok pesan dengan konversi nilai ascii
        words[i >> 2] |= j << ((3-i) % 4)*8;
    }
    // setiap blok pesan dipecah menjadi 16 kata dan 32-bit
    words[words.length] = ((asciiBitLength / maxWord) | 0);
    words[words.length] = (asciiBitLength)
    
    // memproses kompresi setiap potongan blok pesan
    for (j = 0; j < words.length; ++j) {
        let w = words.slice(j, j += 16); 
        let oldHash = hash;
        // ambil 8 kata hash awal, agar sisanya tidak masuk perhitungan
        hash = hash.slice(0, 8);
        
        // operasi-operasi yang diperlukan dalam algoritma SHA-256
        for (i = 0; i < 64; ++i) {
            // mengambil nilai yang akan dirotasi
            let w15 = w[i - 15], w2 = w[i - 2];
            let a = hash[0], e = hash[4];
            
            // perbaruan pesan menjadi 64 kata melalui iterasi
            let temp1 = hash[7]
                + (rightRotate(e, 6) ^ rightRotate(e, 11) ^ rightRotate(e, 25)) 
                + ((e & hash[5]) ^ ((~e) & hash[6])) 
                + k[i]
                // perluas pesan jika diperlukan
                + (w[i] = (i < 16) ? w[i] : (
                        w[i - 16]
                        + (rightRotate(w15, 7) ^ rightRotate(w15, 18) ^ (w15 >>> 3)) 
                        + w[i - 7]
                        + (rightRotate(w2, 17) ^ rightRotate(w2, 19) ^ (w2 >>> 10)) 
                    ) | 0
                );
            let temp2 = (rightRotate(a, 2) ^ rightRotate(a, 13) ^ rightRotate(a, 22)) 
                + ((a & hash[1]) ^ (a & hash[2]) ^ (hash[1] & hash[2])); 
            
            hash = [(temp1 + temp2) | 0].concat(hash); 
            hash[4] = (hash[4] + temp1) | 0;
        }
        
        for (i = 0; i < 8; ++i) {
            // menjumlahkan hash dengan hash lama dan jumlah bilangan tetap bulat (32-bit)
            hash[i] = (hash[i] + oldHash[i]) | 0;
        }
    }
    
    // setelah semua blok pesan diproses state hash terakhir diubah menjadi string heksadesimal
    for (i = 0; i < 8; ++i) {
        for (j = 3; 0 <= j; --j) {
            let b = (hash[i] >> (j*8)) & 255;
            // konversi nilai desimal menjadi heksadesimal menggunakan basis 16
            result += ((b < 16) ? 0 : '') + b.toString(16);
        }
    }

    // dikembalikan sebagai hasil hash SHA-256
    return result;
};

export default sha256;
